<template>
  <div class="Training">
    <div class="fluid container">
      <div class="commonWhiteBg low">
        <a-divider class="topTitle">区块链培训系统</a-divider>
        <a-row class="collapse_ul">
            <a-col class="ul_l" :md="24" :lg="24" :xl="12">
              <h3>中国知链“区块链+教育”人才培养系统介绍</h3>
              <p>中国知链“区块链+教育”人才培养系统课程体系是由工信部指导，武汉大学研发。</p>
              <p>近几年，作为数字经济发展的基石，区块链技术被冠以“第四次工业革命新技术”，受到广泛关注。随着区块链在行业发展爆发式的增长，必将面临行业相关人才储备严重不足。</p>
              <p>2021年区块链被列入“十四五”国家重点研发计划和数字经济重点产业，“区块链人才培养” 至关重要。因此，我们要加强人才队伍建设，建立并完善人才培养体系，打造高层次人才培养平台。</p>
              <p>中国知链“区块链+教育”人才培养系统从区块链基础知识到区块链技术开发与代码实战演练，持续完善区块链技术专业课程，不断探索人才培养的有效途径，加强专业人才队伍建设，培养社会需要的有创新能力、学习能力、实践能力的优秀区块链技术人才。</p>
              <img src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/Training01.png" alt="">
            </a-col>
            <a-col class="ul_r" :md="24" :lg="24" :xl="12">
              <img src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/Training02.png" alt="">
            </a-col>
        </a-row>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Training',

  data () {
    return {
    }
  },

  methods: {
  },
}
</script>

<style lang='scss' scoped>
.Training {
  background: url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/blueBg.svg') repeat-x;
  background-size: auto 224px;
  padding-top: 56px;
}
.low {
  padding: 72px 56px 56px;
  .topTitle {
    color: rgba(0, 18, 22, 0.65);
    font-size: 32px;
  }
  .topTitle::before,
  .topTitle::after{
    top: 0;
  }
  .collapse_ul{
    padding: 32px;
    box-shadow: 0 0 16px rgba(0, 109, 117, 0.08);
    border-radius: 8px 0 0 8px;
    .ul_l{
      h3{
        font-size: 16px;
        font-weight: bold;
        line-height: 30px;
        padding-bottom: 20px;
        padding-left: 20px;
        position: relative;
      }
      h3::before{
        content: '';
        width: 6px;
        height: 24px;
        border-radius: 2px;
        background: #007DFF;
        position: absolute;
        left: 0;
        top: 3px;
      }
      p{
        color: rgba(0, 18, 22, 0.65);
        text-indent: 2em;
        padding: 10px 0;
        line-height:1.769230769230769;
      }
      img{
        margin: 20px 0;
        max-width: 100%;
      }
    }
    .ul_r{
      img{
        width: 100%;
        padding: 50px 0 50px 30px;
      }
    }
  }
}

@media screen and (min-width: 1px) and (max-width: 767px) {
  .Training{padding-top: 24px;}
  .low {
    padding: 24px 16px 16px;
    .topTitle {
      color: rgba(0, 18, 22, 0.65);
      font-size: 24px;
    }
    .collapse_ul{
      .ul_r{
        img{
          padding: 50px 0;
        }
      }
    }
  }
}
</style>
